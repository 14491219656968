<template>
  <div :class="'breadcrumbs ' + position + (isBack ? ' is-back ' : '')" v-if="list">
    <template v-for="(b, k) in list">
      <NuxtLink
        v-if="list.length-1 != k"
        :to="b.link"
        class="item link"
        >
        {{ b.name }}
      </NuxtLink>

      <i
        v-if="list.length-1 > k"
        class="icon-angle-right"
      ></i>

      <span
        v-if="list.length-1 == k"
        class="item name"
        >
        {{ b.name }}
      </span>
    </template>
    <NuxtLink
      v-if="isBack"
      :to="backLink"
      class="item back"
      >
      <i class="icon-angle-left"></i>
      Back to {{ backName }}
    </NuxtLink>
  </div>
</template>

<script setup lang="ts">

interface PageProps {
  list: Array<any>,
  position?: string  ,
  isBack?: boolean
}

const props = withDefaults(defineProps<PageProps>(), {
  list: [],
  position: 'left',
  isBack: true  
})

const backLink = computed(() => {
  if (props.list?.length < 2) return '/'
  return props.list[props.list.length - 2].link
})

const backName = computed(() => {
  if (props.list?.length < 2) return '/'
  return props.list[props.list.length - 2].name
})

</script>

<style scoped>
.breadcrumbs {
  color: var(--gd-link-color);
  display: flex;
  align-items: center;
  gap: 5px;
}
.breadcrumbs .back{
  display: none;
}
.breadcrumbs.right{
  justify-content: flex-end;
}
.breadcrumbs .item{
  color: var(--gd-link-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
  flex-shrink: 0;
}
.breadcrumbs .item:last-child{
  flex-shrink: 1;
  max-width: 70%;
}
.breadcrumbs i{

}
/* .breadcrumbs .item::before{
  content: "\f103";
  font-family: icons !important;
  font-style: normal;
  font-weight: normal !important;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  margin-left: -16px;
  margin-top: 3px;
} */
.breadcrumbs .item:first-child{
  margin-left: 0;
}
.breadcrumbs .item:first-child::before{
  display: none;
}

@container pb (max-width: 600px) {
  .breadcrumbs.right{
    justify-content: flex-start;
  }
}




/* is back */

@container pb (max-width: 600px) {
  .breadcrumbs.is-back > *:not(.back){
    display: none;
  }
  /* .breadcrumbs.is-back > *.back{
    display: block;
    opacity: 0.7;
  } */
}


</style>